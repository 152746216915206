const publications = [
    {
        title: 'Retri3D: 3D Neural Graphics Representation Retrieval',
        author: 'Yushi Guan, Daniel Kwan, Jean Sebastien Dandurand, Xi Yan, Ruofan Liang, Yuxuan Zhang, Nilesh Jain, Nilesh Ahuja, Selvakumar Panneer, Nandita Vijaykumar',
        link: 'https://openreview.net/forum?id=q3EbOXb4y1',
        conference: 'ICLR 2025'
    },
    {
        title: 'INRet: A General Framework for Accurate Retrieval of INRs for Shapes',
        author: 'Gavin Guan, Yuchen Chen, Haoze Deng, Daniel Kwan, Ruofan Liang, Selvakumar Panneer, Nilesh Jain, Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2501.15722',
        conference: '3DV 2025'
    },
    {
        title: 'DEQuify your force field: Towards efficient simulations using deep equilibrium models',
        author: 'Andreas Burger, Luca Thiede, Alán Aspuru-Guzik, Nandita Vijaykumar',
        link: 'https://openreview.net/forum?id=rynb4Vn8rb',
        conference: 'ELLIS Machine Learning for Molecules Workshop 2024'
    },
    {
        title: 'ARC: Warp-level Adaptive Atomic Reduction in GPUs to Accelerate Differentiable Rendering',
        author: 'Adrian Zhao, Sankeerth Durvasula, Fan Chen, Ruofan Liang, Pawan Kumar Sanjaya, Yushi Guan, Christina Giannoula, Nandita Vijaykumar',
        link: 'arc',
        conference: 'ASPLOS 2025'
    },
    {
        title: 'DISORF: A Distributed Online 3D Reconstruction Framework for Mobile Robots',
        author: 'Chunlin Li, Hanrui Fan, Xiaorui Huang, Ruofan Liang, Sankeerth Durvasula, Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2403.00228',
        conference: 'IEEE Robotics and Automation Letters 2025'
    },
    {
        title: 'Photorealistic Object Insertion with Diffusion-Guided Inverse Rendering',
        author: 'Ruofan Liang, Zan Gojcic, Merlin Nimier-David, David Acuna, Nandita Vijaykumar, Sanja Fidler, Zian Wang',
        link: 'https://arxiv.org/abs/2408.09702',
        conference: 'ECCV 2024'
    },
    {
        title: 'ACE: Efficient GPU Kernel Concurrency for Input-Dependent Irregular Computational Graphs',
        author: 'Sankeerth Durvasula, Adrian Zhao, Raymond Kiguru, Yushi Guan, Zhonghan Chen, Nandita Vijaykumar',
        link: 'ace',
        conference: 'PACT 2024'
    },
    {
        title: 'Distributed Training of Neural Radiance Fields: A Performance Characterization',
        author: 'Adrian Zhao, Louis Zhang, Sankeerth Durvasula, Fan Chen, Nilesh Jain, Selvakumar Panneer, Nandita Vijaykumar',
        link: 'https://ieeexplore.ieee.org/document/10590055',
        conference: 'ISPASS 2024',
        extraResources: [
            {
                name: 'Poster',
                format: {
                    pdf: 'distnerfc_poster',
                },
            },
            {
                name: 'Lightning Talk Slides',
                format: {
                    pdf: 'distnerfc_slides'
                }
            }
        ]
    },
    {
        title: 'Proteus: Preserving Model Confidentiality during Graph Optimizations',
        author: 'Yubo Gao, Maryam Haghifam, Christina Giannoula, Renbo Tu, Gennady Pekhimenko, Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2404.12512',
        conference: 'MLSys 2024'
    },
    {
        title: 'DISTWAR: Fast Differentiable Rendering on Raster-based Rendering Pipelines',
        author: 'Sankeerth Durvasula, Adrian Zhao, Fan Chen, Ruofan Liang, Pawan Kumar Sanjaya, Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2401.05345',
        extraResources: [
            {
                name: 'Code',
                format: {
                    link: 'https://github.com/Accelsnow/gaussian-splatting-distwar',
                },
            }
        ]
    },
    {
        title: 'Utopia: Efficient Address Translation using Hybrid Virtual-to-Physical Address Mapping',
        author: 'Konstantinos Kanellopoulos, Rahul Bera, Kosta Stojiljkovic, Nisa Bostanci, Can Firtina, Rachata Ausavarungnirun, Rakesh Kumar, Nastaran Hajinazar, Mohammad Sadrosadati, Nandita Vijaykumar, Onur Mutlu',
        link: 'https://arxiv.org/abs/2211.12205',
        conference: 'MICRO 2023'
    },
    {
        title: 'ENVIDR: Implicit Differentiable Renderer with Neural Environment Lighting',
        author: 'Ruofan Liang, Huiting Chen, Chunlin Li, Fan Chen, Selvakumar Panneer, and Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2303.13022',
        conference: 'ICCV 2023',
        extraResources: [
            {
                name: 'Project Page',
                format: {
                    link: 'https://nexuslrf.github.io/ENVIDR/'
                }
            }
        ]
    },
    {
        title: 'Ev-Conv: Fast CNN Inference on Event Camera Inputs For High-Speed Robot Perception',
        author: 'Sankeerth Durvasula, Yushi Guan, Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2303.04670',
        conference: 'RA-L',
        extraResources: [
            {
                name: 'Slides (presented at IROS 2023)',
                format: {
                    link: 'https://drive.google.com/drive/folders/1icOctzZ1ClA9kRR9gL52Td0RQG3NPORz?usp=sharing'
                }
            }
        ]
    },
    {
        title: 'SPIDR: SDF-based Neural Point Fields for Illumination and Deformation',
        author: 'Ruofan Liang, Jiahao Zhang, Haoda Li, Chen Yang, Yushi Guan, Nandita Vijaykumar',
        link: 'https://arxiv.org/abs/2210.08398',
        conference: 'CVPRW 2023 - XRNeRF',
        extraResources: [
            {
                name: 'Project Page',
                format: {
                    link: 'https://nexuslrf.github.io/SPIDR_webpage/'
                }
            }
        ]
    },
    {
        title: 'DaeMon: Architectural Support for Efficient Data Movement in Fully Disaggregated Systems',
        link: 'https://arxiv.org/abs/2301.00414',
        author: 'Christina Giannoula, Kailong Huang, Jonathan Tang, Nectarios Koziris, Georgios Goumas, Zeshan Chishti, and Nandita Vijaykumar',
        conference: 'ACM Sigmetrics 2023',
    },
    {
        title: 'ALP: Alleviating CPU-Memory Data Movement Overheads in Memory-Centric Systems',
        link: 'https://arxiv.org/abs/2212.06292',
        author:
            'Nika Mansouri Ghiasi, Nandita Vijaykumar, Geraldo F. Oliveira, Lois Orosa, Ivan Fernandez, Mohammad Sadrosadati, Konstantinos Kanellopoulos, Nastaran Hajinazar, Juan Gomez-Luna, and Onur Mutlu',
        conference: 'Transactions on Emerging Topics in Computing (TETC) 2022',
    },
    {
        title: 'VoxelCache: Accelerating Online Mapping in Robotics and 3D Reconstruction Tasks',
        link: 'https://arxiv.org/abs/2210.08729',
        author:
            'Sankeerth Durvasula, Raymond Kigaru, Samarth Mathur, Jimmy Lin, Jenny Xu, and Nandita Vijaykumar',
        conference: 'PACT 2022',
    },
    {
        title: 'GPUPool: A Holistic Approach to Fine-Grained GPU Sharing in the Cloud',
        link: 'gpupool',
        author: 'Xiodan Serina Tan, Pavel Golikov, Nandita Vijaykumar, and Gennady Pekhimenko',
        conference: 'PACT 2022',
    },
    {
        title: 'CoordX: Accelerating Implicit Neural Representation with a Split MLP Architecture',
        link: 'https://arxiv.org/abs/2201.12425',
        author: 'Ruofan Liang, Hongyi Sun, and Nandita Vijaykumar',
        conference: 'ICLR 2022',
        extraResources: [
            {
                name: 'Code',
                format: {
                    link: 'https://github.com/nexuslrf/CoordX',
                },
            },
            {
                name: 'Slides',
                format: {
                    link: 'https://1drv.ms/b/s!AtiMpA7HPe0Qhs1qlmMdPFgK6xwjag?e=iUlWgh'
                }
            }
        ]
    },
    {
        title:
            'MetaSys: A Practical Open-source Metadata Management System to Implement and Evaluate Cross-layer Optimizations',
        link: 'https://arxiv.org/abs/2105.08123',
        author:
            'Nandita Vijaykumar, Ataberk Olgun, Konstantinos Kanellopoulos, F. Nisa Bostanci, Hasan Hassan, Mehrshad Lotfi, Phillip B. Gibbons, and Onur Mutlu',
        conference: 'ACM Transactions on Architecture and Code Optimization (TACO) 2022',
    },
    {
        title:
            'GenStore: A High-Performance and Energy-Efficient In-Storage Computing System for Genome Sequence Analysis',
        link: 'https://arxiv.org/abs/2202.10400',
        author:
            'Nika Mansouri Ghiasi, Jisung Park, Harun Mustafa, Jeremie Kim, Ataberk Olgun, Arvid Gollwitzer, Damla Senol Cali, Can Firtina, Haiyu Mao, Nour Almadhoun Alserr, Rachata Ausavarungnirun, Nandita Vijaykumar, Mohammed Alser, and Onur Mutlu',
        conference: 'ASPLOS 2022',
    },
    {
        title: 'DAMOV: A New Methodology and Benchmark Suite for Evaluating Data Movement Bottlenecks',
        link: 'https://arxiv.org/abs/2105.03725',
        author:
            'Geraldo F. Oliveira, Juan Gomez-Luna, Lois Orosa, Saugata Ghose, Nandita Vijaykumar, Ivan fernandez, Mohammad Sadrosadati, and Onur Mutlu',
        conference: 'IEEE Access, September 2021',
    },
    {
        title:
            'QUAC-TRNG: High-Throughput True Random Number Generation Using Quadruple Row Activation in Commodity DRAM Chips',
        link: 'https://arxiv.org/abs/2105.08955',
        author:
            'Ataberk Olgun, Minesh Patel, A. Giray Yaglikci, Haocong Luo, Jeremie S. Kim, F. Nisa Bostanci, Nandita Vijaykumar, Oguz Ergin, and Onur Mutlu',
        conference: 'ISCA 2021',
    },
    {
        title: 'SynCron: Efficient Synchronization Support for Near-Data-Processing Architectures',
        link: 'https://arxiv.org/abs/2101.07557',
        author:
            'Christina Giannoula, Nandita Vijaykumar, Nikela Papadopoulou, Vasileios Karakostas, Ivan Fernandez, Juan Gómez-Luna, Lois Orosa, Nectarios Koziris, Georgios Goumas, and Onur Mutlu',
        conference: 'HPCA 2021',
    },
    {
        title: 'Echo: Compiler-based GPU Memory Footprint Reduction for LSTM RNN Training',
        link: 'https://arxiv.org/abs/1805.08899',
        author: 'Bojian Zheng, Nandita Vijaykumar, Gennady Pekhimenko',
        conference: 'ISCA 2020',
    },
    {
        title:
            'SysScale: Exploiting Multi-domain Dynamic Voltage and Frequency Scaling for Energy Efficient Mobile Processors',
        link: 'https://arxiv.org/abs/2005.07613',
        author:
            'Jawad Haj-Yahya, Mohammed Alser, Jeremie Kim, A. Giray Yaglikci, Nandita Vijaykumar, Efraim Rotem, and Onur Mutlu',
        conference: 'ISCA 2020',
    },
    {
        title:
            'Enhancing Programmability, Portability, and Performance with Rich Cross-Layer Abstractions',
        link: 'https://arxiv.org/abs/1911.05660',
        author: 'Nandita Vijaykumar',
        conference: 'PhD Thesis, Carnegie Mellon University',
        extraResources: [
            {
                name: 'Slides',
                format: {
                    pptx: 'thesis-slide',
                    pdf: 'thesis-slide',
                },
            },
        ]
    },
    {
        title:
            'SMASH: Co-designing Software Compression and Hardware-Accelerated Indexing for Efficient Sparse Matrix Operations',
        link: 'https://arxiv.org/abs/1910.10776',
        author:
            'Konstantinos Kanellopoulos, Nandita Vijaykumar, Christina Giannoula, Roknoddin Azizi, Skanda Koppula, Nika Mansouri Ghiasi, Taha Shahroodi, Juan Gomez-Luna, and Onur Mutlu',
        conference: 'MICRO 2019',
    },
    {
        title:
            'CROW: A Low-Cost Substrate for Improving DRAM Performance, Energy Efficiency, and Reliability',
        link: 'crow',
        author:
            'Hasan Hassan, Minesh Patel, Jeremie S. Kim, A. Giray Yaglikci, Nandita Vijaykumar, Nika Mansourighiasi, Saugata Ghose, and Onur Mutlu',
        conference: 'ISCA 2019',
    },
    {
        title:
            'A Case for Richer Cross-layer Abstractions: Bridging the Semantic Gap with Expressive Memory',
        link: 'cross-layer-abstractions',
        author:
            'Nandita Vijaykumar, Abhilasha Jain, Diptesh Majumdar, Kevin Hsieh, Gennady Pekhimenko, Eiman Ebrahimi, Nastaran Hajinazar, Phillip B. Gibbons, Onur Mutlu',
        conference: 'ISCA 2018',
        extraResources: [
            {
                name: 'Slides',
                format: {
                    pptx: 'expressive-memory',
                    pdf: 'expressive-memory',
                },
            },
            {
                name: 'Lightning Talk Slides',
                format: {
                    pptx: 'expressive-memory-lightning',
                    pdf: 'expressive-memory-lightning',
                },
            },
            {
                name: 'Lightning Talk Video',
                format: {
                    video: 'https://www.youtube.com/watch?v=hasM-p7Ag_g',
                },
            },
        ]
    },
    {
        title:
            'The Locality Descriptor: A Holistic Cross-Layer Abstraction to Express Data Locality in GPUs',
        link: 'locality-descriptor',
        author: 'Nandita Vijaykumar, Eiman Ebrahimi, Kevin Hsieh, Phillip B. Gibbons, Onur Mutlu',
        conference: 'ISCA 2018',
        extraResources: [
            {
                name: 'Slides',
                format: {
                    pptx: 'locality-descriptor-slides',
                    pdf: 'locality-descriptor-slides',
                },
            },
            {
                name: 'Lightning Talk Slides',
                format: {
                    pptx: 'locality-descriptor-lightning',
                    pdf: 'locality-descriptor-lightning',
                },
            },
            {
                name: 'Lightning Talk Video',
                format: {
                    video: 'https://www.youtube.com/watch?v=hasM-p7Ag_g',
                },
            },
        ]
    },
    {
        title: 'Gaia: Geo-Distributed Machine Learning Approaching LAN Speeds',
        link: 'gaia',
        author:
            'Kevin Hsieh, Aaron Harlap, Nandita Vijaykumar, Dimitris Konomis, Gregory R. Ganger, Phillip B. Gibbons, Onur Mutlu',
        conference: 'NSDI 2017',
    },
    {
        title:
            'SoftMC: A Flexible and Practical Open-Source Infrastructure for Enabling Experimental DRAM Studies',
        link: 'https://arxiv.org/abs/1805.03195',
        author:
            'Hasan Hassan, Nandita Vijaykumar, Samira Khan, Saugata Ghose, Kevin Chang, Gennady Pekhimenko, Donghyuk Lee, Oguz Ergin, Onur Mutlu',
        conference: 'HPCA 2017',
    },
    {
        title: 'Zorua: A Holistic Approach to Resource Virtualization in GPUs',
        link: 'zorua',
        author:
            'Nandita Vijaykumar, Kevin Hsieh, Gennady Pekhimenko, Samira Khan, Ashish Shrestha, Saugata Ghose, Adwait Jog, Phillip B. Gibbons, Onur Mutlu',
        conference: 'MICRO 2016',
        extraResources: [
            {
                name: 'Slides',
                format: {
                    pptx: 'zorua',
                    pdf: 'zorua-slides',
                },
            },
            {
                name: 'Lightning Talk Slides',
                format: {
                    pptx: 'zorua-lightning',
                    pdf: 'zorua-lightning',
                },
            },
            {
                name: 'Poster',
                format: {
                    pdf: 'zorua-poster',
                },
            },
        ]
    },
    {
        title: 'Accelerating Pointer Chasing in 3D-Stacked Memory: Challenges, Mechanisms, Evaluation',
        link: 'accelerating-pointer-chasing',
        author:
            'Kevin Hsieh, Samira Khan, Nandita Vijaykumar, Kevin K. Chang, Amirali Boroumand, Saugata Ghose, Onur Mutlu',
        conference: 'ICCD 2016',
    },
    {
        title:
            'Transparent Offloading and Mapping (TOM): Enabling Programmer-Transparent Near-Data Processing in GPU Systems',
        link: 'tom',
        author:
            "Kevin Hsieh, Eiman Ebrahimi, Gwangsun Kim, Niladrish Chatterjee, Mike O'Connor, Nandita Vijaykumar, Onur Mutlu, Stephen Keckler",
        conference: 'ISCA 2016',
    },
    {
        title: 'ChargeCache: Reducing DRAM Latency by Exploiting Row Access Locality',
        link: 'chargecache',
        author:
            'Hasan Hassan, Gennady Pekhimenko, Nandita Vijaykumar, Vivek Seshadri, Donghyuk Lee, Oguz Ergin, Onur Mutlu',
        conference: 'HPCA 2016',
    },
    {
        title: 'A Case for Toggle-Aware Compression for GPU Systems',
        link: 'toggle-aware-compression',
        author:
            'Gennady Pekhimenko, Evgeny Bolotin, Nandita Vijaykumar, Onur Mutlu, Todd C. Mowry, Stephen W. Keckler',
        conference: 'HPCA 2016',
        extraResources: [
            {
                name: 'Slides',
                format: {
                    pptx: 'caba-gpu-slides',
                    pdf: 'caba-gpu-slides',
                },
            },
            {
                name: 'Lightning Talk Slides',
                format: {
                    pptx: 'caba-gpu-slides-lightning',
                    pdf: 'caba-gpu-slides-lightning',
                },
            },
        ]
    },
    {
        title:
            'A Case for Core-Assisted Bottleneck Acceleration in GPUs: Enabling Flexible Data Compression with Assist Warps',
        link: 'core-assisted-bottleneck-acceleration',
        author:
            'Nandita Vijaykumar, Gennady Pekhimenko, Adwait Jog, Abhishek Bhowmick, Rachata Ausavarungnirun, Chita Das, Mahmut Kandemir, Todd C. Mowry, and Onur Mutlu',
        conference: 'ISCA 2015',
    },
];

const conferenceLink = {
    'MICRO 2023': 'https://microarch.org/micro56/',
    'ICCV 2023': 'https://iccv2023.thecvf.com/',
    'CVPRW 2023 - XRNeRF': 'https://sites.google.com/view/xrnerf/',
    'RA-L': 'https://www.ieee-ras.org/publications/ra-l',
    'IROS 2023': 'https://ieee-iros.org/',
    'ACM Sigmetrics 2023': 'https://www.sigmetrics.org/sigmetrics2023/',
    'Transactions on Emerging Topics in Computing (TETC) 2022': 'https://www.computer.org/web/tetc',
    'PACT 2022': 'https://pact22.cs.illinois.edu/',
    'ICLR 2022': 'https://iclr.cc/Conferences/2022',
    'ACM Transactions on Architecture and Code Optimization (TACO) 2022':
        'https://dl.acm.org/journal/taco',
    'ASPLOS 2022': 'https://asplos-conference.org/2022/index.html',
    'IEEE Access, September 2021': 'https://ieeeaccess.ieee.org/tag/2021/',
    'ISCA 2021': 'https://iscaconf.org/isca2021/',
    'HPCA 2021': 'https://hpca-conf.org/2021/',
    'ISCA 2020': 'https://iscaconf.org/isca2020/',
    'MICRO 2019': 'https://www.microarch.org/micro52/',
    'ISCA 2019': 'https://iscaconf.org/isca2019/',
    'ISCA 2018': 'https://iscaconf.org/isca2018/',
    'NSDI 2017': 'https://www.usenix.org/conference/nsdi17',
    'HPCA 2017': 'https://hpca2017.org/',
    'MICRO 2016': 'https://www.microarch.org/micro49/',
    'ICCD 2016': 'https://dblp.org/db/conf/iccd/iccd2016.html',
    'ISCA 2016': 'http://isca2016.eecs.umich.edu/',
    'HPCA 2016': 'https://hpca22.ac.upc.edu/',
    'ISCA 2015': 'https://iscaconf.org/isca2015/',
    'MLSys 2024': 'https://mlsys.org/Conferences/2024',
    'PACT 2024': 'https://pact2024.github.io/',
    'ISPASS 2024': 'https://www.ispass.org/ispass2024/',
    'ECCV 2024': 'https://eccv.ecva.net/',
    'IEEE Robotics and Automation Letters 2025': 'https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=7083369',
    'ASPLOS 2025': 'https://www.asplos-conference.org/asplos2025/',
    'ELLIS Machine Learning for Molecules Workshop 2024': 'https://moleculediscovery.github.io/workshop2024/',
    '3DV 2025': 'https://3dvconf.github.io/2025/',
    'ICLR 2025': 'https://iclr.cc/Conferences/2025'
};

export {publications, conferenceLink};
